.add-liquidity-page {
    background-color: #1c2a359e;
    padding: 2rem 2rem 0 2rem;
    border-radius: 24px;
    display: flex; 
    flex-direction: column;
    width: 1080px;

  
    &__stepper { 
      display: flex;
      justify-content: center;
      margin-left: -2rem;
      margin-right: -2rem;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #1d272e;
      border-bottom: 1px solid #11181e;
      border-top: 1px solid #11181e;

   }

   &__header {

     &-title {
       font-size: 25px;
     }
   }
}

.add-buttons {

  margin-left: -2rem;
  margin-right: -2rem;
  padding: 1rem 2rem;
  background-color: #1d272e;
  border-top: 1px solid #243956;
  border-radius: 0 0 24px 24px;


  &__liquidity, &__next {
    padding: 8px 1rem;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    background-color: #6f58f6;
    color: white;

    &:not(:disabled) {
      &:hover {
        background-color: #654bf8;
      }
    }

    &:disabled {
      background-color: #9484f0af;
      color: #aeaeae;
    }

  }

  &__prev {
    border: none;
    background-color: transparent;
    padding: 0;
    color: white;

    &-text {
      &--mobile {
        display: none;
      }
    }

    &:hover {
      color: #c1c1c1;
    }
  }

}


@media screen and (max-width: 960px) {
  .add-liquidity-page {
    width: 100%;
    padding: 2rem 1rem 0 1rem;
    margin-bottom: 2rem;

    &__stepper {
      overflow: auto;
      white-space: nowrap;
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  .add-buttons {
    margin-left: -1rem;
    margin-right: -1rem;

    &__prev {
      &-text {
        display: none;
        &--mobile {
          display: inline;
        }
      }
    }
  }

}

@media screen and (max-width: 720px) {

}

@media screen and (max-width: 500px) {
  .add-liquidity-page {

    &__stepper {
      padding: 1rem;
    }
  }
}