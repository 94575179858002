.step-title {
        &__circle {
           width: 30px;
           height: 30px;
           border-radius: 50%;
           border: 2px solid #8A78F2;
   
           &.done {
             background-color: #5dba51;
             border-color: #5dba51;
           }
        }
        &__text {
         font-size: 18px;
        }
}