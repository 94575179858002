.footer {
    &-wrapper {
        background-color: var(--dark-blue);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 1rem;
        border-top: 1px solid #140e3a;
    }
}
